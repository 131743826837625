<template>
  <!-- Тестовая страница карточки мпк -->
  <!-- Вид страниц как для админа -->
  <div class="container-page">
    <div class="card-mpk">
      <p class="card-mpk__title title-inner">Карточка поручения по МПК</p>
      <div class="card-mpk__body">
        <!-- Карточка -->
        <div class="card-mpk__card">
          <!-- body card -->
          <div class="card-mpk__card-body">
            <!-- row -->
            <div class="card-mpk__card-row">
              <!-- cols -->
              <div class="card-mpk__card-col1">
                <p class="title-column">МПК</p>
                <p>
                  {{ item.mpkName }}
                </p>
              </div>
              <div class="card-mpk__card-col2">
                <p class="title-column">Мероприятия</p>
                <p>
                  {{ item.eventName }}
                </p>
              </div>
            </div>
            <!-- row -->
            <div class="card-mpk__card-row">
              <!-- cols -->
              <div class="card-mpk__card-col1">
                <p class="title-column">Исполнитель</p>
                <p v-if="item.assignmentExecutors">
                  {{ item.assignmentExecutors.map((x) => x.organizationName).join(', ') }}
                </p>
              </div>
              <div class="card-mpk__card-col2">
                <p class="title-column">Реквизиты</p>
                <p>
                  {{ item.razdelProtocal }}
                </p>
              </div>
            </div>
            <!-- row -->
            <div class="card-mpk__card-row">
              <!-- cols -->
              <div class="card-mpk__card-col1">
                <p class="title-column">Содержание поручения</p>
                <p>
                  {{ item.brief }}
                </p>
              </div>
              <div class="card-mpk__card-col2">
                <p class="title-column">Срок</p>
                <p>
                  {{ item.srok }}
                  <span class="deadline">Срок контроля: {{ $momentFormat(item.deadline, 'DD MMMM YYYY') }}</span>
                  <span v-if="item.isPermanent">Выполняется на постоянной основе</span>
                </p>
              </div>
            </div>

            <template v-if="item.isStart">
              <template v-for="assignmentExecutor in item.assignmentExecutors" :key="assignmentExecutor.id">
                <!-- row  report-->
                <div class="card-mpk__card-row report">
                  <!-- cols -->
                  <div class="card-mpk__card-col1 report-container">
                    <p class="title-column">
                      Отчет
                      <!-- Имя организации от которой оставлен отчет -->
                      <!-- Виден админу -->
                      <span class="report-name" v-if="viewAllExecutor">{{ assignmentExecutor.organizationName }}</span>
                    </p>
                    <div class="report-column">
                      <!-- текст отчета здесь -->
                      <template v-for="el in assignmentExecutor.mpkAssignmentExecutorReports" :key="el.id">
                        <p class="report-text">
                          {{ el.report }}
                          <FileMultiUpload
                            :entity="el"
                            prop-name="files"
                            readonly
                            v-if="el.files && el.files.length > 0"
                          />
                        </p>
                      </template>

                      <div
                        v-if="
                          assignmentExecutor.statusId >= 3 && assignmentExecutor.statusId <= mpkAssignemntStatusDoneId
                        "
                      >
                        <div class="checkboxInput">
                          <input
                            type="checkbox"
                            v-model="assignmentExecutor.isExecute"
                            id="chExecute"
                            :disabled="!assignmentExecutor.canApprove"
                          />
                          <label for="chExecute">Поручение выполнено</label>
                        </div>
                      </div>
                      <div class="btn-container d-flex flex-wrap w100">
                        <template v-if="!assignmentExecutor.postponed">
                          <template v-if="assignmentExecutor.canEdit && assignmentExecutor.statusId < 3">
                            <template v-if="assignmentExecutor.canApprove">
                              <ButtonStock
                                title="На согласование"
                                @click="confirmReport(assignmentExecutor)"
                                :disabled="!assignmentExecutor.report"
                              />
                              <ButtonStock
                                btnType="secondary"
                                @click="createReport(assignmentExecutor)"
                                :title="assignmentExecutor.report ? 'Отредактировать отчет' : 'Внести отчет'"
                              />
                            </template>
                            <template v-else>
                              <ButtonStock title="Внести отчет" @click="createReport(assignmentExecutor)" />
                            </template>
                          </template>
                          <template v-else-if="assignmentExecutor.canApprove">
                            <ButtonStock
                              title="Отредактировать отчет"
                              @click="createReport(assignmentExecutor)"
                              v-if="assignmentExecutor.canEdit"
                            />
                            <ButtonStock title="Согласовать" @click="confirmReport(assignmentExecutor)" />
                            <ButtonStock
                              btnType="secondary"
                              @click="cancelReport(assignmentExecutor)"
                              title="Отклонить отчет"
                            />
                          </template>
                          <template v-if="assignmentExecutor.canReturnFromDone">
                            <ButtonStock title="Вернуть в работу" @click="returnFromDone(assignmentExecutor)" />
                          </template>
                        </template>

                        <template v-if="assignmentExecutor.canPostpone">
                          <ButtonStock
                            btnType="secondary"
                            :title="assignmentExecutor.postponed ? 'Возобновить' : 'Приостановить'"
                            @click="setPostponed(assignmentExecutor)"
                          />
                          <ButtonStock
                            btnType="secondary"
                            title="Не выполнено"
                            @click="setCancelStatus(6, assignmentExecutor)"
                          />
                          <ButtonStock
                            btnType="secondary"
                            title="Не выполнено по вине иностранной стороны"
                            @click="setCancelStatus(7, assignmentExecutor)"
                          />
                        </template>
                        <ButtonStock
                          v-if="
                            assignmentExecutor.mpkAssignmentExecutorRefuses &&
                            assignmentExecutor.mpkAssignmentExecutorRefuses.length > 0
                          "
                          btnType="secondary"
                          title="Причины отказа"
                          @click="showRefuseReason(assignmentExecutor)"
                        />
                        <ButtonStock
                          v-if="
                            assignmentExecutor.mpkAssignmentExecutorConfirms &&
                            assignmentExecutor.mpkAssignmentExecutorConfirms.length > 0
                          "
                          btnType="secondary"
                          title="Комментарии"
                          @click="showComments(assignmentExecutor.mpkAssignmentExecutorConfirms)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-mpk__card-col2 status">
                    <p class="title-column">Статус</p>
                    <div class="d-flex status-container">
                      <IconComponent :name="getStatusClassIcon(assignmentExecutor)" />
                      <span :class="getStatusClassColor(assignmentExecutor)">{{ assignmentExecutor.statusName }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <template v-else>
              <div class="card-mpk__card-row">
                <div class="card-mpk__card-col1 report-container">
                  <div class="report-column">
                    <div class="btn-container d-flex flex-wrap w100">
                      <ButtonStock title="На исполнение" v-if="canStart" @click="onStart()" />
                      <ButtonStock title="Оставить комментарий" v-if="canSendComment" @click="startComment()" />
                      <ButtonStock
                        title="Комментарии"
                        v-if="item.assignmentComments && item.assignmentComments.length > 0"
                        @click="showComments(item.assignmentComments)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <AssignmentChat :orgList="orgs" :assignmentId="id" />
      </div>

      <div class="mt-3">
        <ButtonStock btnType="secondary" title="Закрыть" v-on:click="close" />
      </div>

      <ModalComponent title="На исполнение" @close="dialogOnStart = false" v-model="dialogOnStart">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16">
            <p>Реквизиты</p>
            <textarea placeholder="Введите реквизиты" v-model="rekvizit"></textarea>
            <div class="invalid-msg" v-if="v$.rekvizit.$error">Поле обязательно для заполнения</div>
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Подтвердить" @click="onStartSend" />
            <ButtonStock @click="dialogOnStart = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Согласовать" @close="dialogStartComment = false" v-model="dialogStartComment">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16">
            <p>Комментарий</p>
            <textarea placeholder="Напишите комментарий" v-model="comment"></textarea>
            <div class="invalid-msg" v-if="v$.comment.$error">Поле обязательно для заполнения</div>
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Подтвердить" @click="startCommentSend" />
            <ButtonStock @click="dialogStartComment = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Согласовать" @close="dialogConfirm = false" v-model="dialogConfirm">
        <div class="form__body cancel">
          <div class="form__body-inputBlock pb-4 mb-4" v-if="showDeadlineInput">
            <p>Срок контроля</p>
            <dateComponent v-model="itemDeadline" :clearable="true" required />
          </div>
          <div class="form__body-inputBlock pb-4 mb-4">
            <p>Комментарий</p>
            <textarea placeholder="Напишите комментарий" v-model="reason"></textarea>
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Подтвердить" @click="confirmReportSend" />
            <ButtonStock @click="dialogConfirm = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Отклонить" @close="dialogCancel = false" v-model="dialogCancel">
        <div class="form__body cancel">
          <div class="form__body-inputBlock pb-4 mb-4">
            <p>Причина</p>
            <textarea placeholder="Напишите причину отклонения" v-model="reason"></textarea>
            <div class="invalid-msg" v-if="v$.reason.$error">Поле обязательно для заполнения</div>
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Подтвердить" @click="cancelReportSend" />
            <ButtonStock @click="dialogCancel = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Поручение не выполнено" @close="dialogCancelStatus = false" v-model="dialogCancelStatus">
        <div class="form__body cancel">
          <div class="form__body-inputBlock pb-4 mb-4">
            <p>Причина</p>
            <textarea placeholder="Напишите причину не выполнения" v-model="reason"></textarea>
            <div class="invalid-msg" v-if="v$.reason.$error">Поле обязательно для заполнения</div>
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Подтвердить" @click="cancelStatusSend" />
            <ButtonStock @click="dialogCancelStatus = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Внести отчет" @close="dialogMakeReport = false" v-model="dialogMakeReport">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16">
            <p>Отчет</p>
            <textarea placeholder="Вставьте или напишите здесь текст отчета" v-model="modal.report"></textarea>
            <div class="invalid-msg" v-if="v$.modal.report.$error">Поле обязательно для заполнения</div>
          </div>
          <div class="form__body-inputBlock mb-16">
            <p>Приложить документы</p>
            <FileMultiUpload
              :entity="modal"
              @deleteFile="removeFile"
              prop-name="files"
              @uploaded="fileUploaded"
              :readonly="isReadonly"
            />
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Сохранить" @click="createReportSend" />
            <ButtonStock @click="dialogMakeReport = false" btnType="whiteGreenBordered" title="Отмена" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Причины отказа" @close="dialogRefuse = false" v-model="dialogRefuse">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16 pb-4">
            <template v-for="reason in refuseReasons" :key="reason.id">
              <p>{{ reason.createdBy.fullName }} ({{ $momentFormat(reason.created, 'DD MMMM YYYY') }})</p>
              <div>{{ reason.reason }}</div>
            </template>
          </div>
          <div>
            <ButtonStock @click="dialogRefuse = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Комментарии" @close="dialogComment = false" v-model="dialogComment">
        <div class="form__body cancel">
          <div class="form__body-inputBlock pb-0 mb-4">
            <template v-for="com in commentList" :key="com.id">
              <p>
                {{ com.createdBy.fullName }}<span v-if="com.organization">, {{ com.organization }}</span> ({{
                  $momentFormat(com.created, 'DD MMMM YYYY')
                }})
              </p>
              <p v-if="com.statusName">Статус: {{ com.statusName }}</p>
              <div class="mb-4">{{ com.comment }}</div>
            </template>
          </div>
          <div>
            <ButtonStock @click="dialogComment = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import dateComponent from '@/common/components/dateComponent.vue';
  import FileMultiUpload from '@/common/components/FileMultiUpload';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/assignment';
  import AssignmentChat from '../components/AssignmentChat.vue';

  export default {
    name: 'AssignmentCard',
    components: {
      ButtonStock,
      IconComponent,
      ModalComponent,
      AssignmentChat,
      FileMultiUpload,
      dateComponent,
    },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        isReadonly: this.$route.params.action === 'view',
        viewAllExecutor: true, //видит ли все отчеты
        mpkAssignemntStatusDoneId: Constants.mpkAssignemntStatusDoneId,

        item: {},
        statusId: 0,
        assignmentExecutorId: 0,
        assignmentExecutor: {},
        modal: {
          report: '',
          files: [],
        },
        reason: '',
        rekvizit: '',
        comment: '',
        refuseReasons: [],
        commentList: [],

        dialogMakeReport: false,
        dialogCancel: false,
        dialogCancelStatus: false,
        dialogRefuse: false,
        dialogConfirm: false,
        dialogComment: false,
        dialogOnStart: false,
        dialogStartComment: false,

        canStart: false,
        canSendComment: false,
        itemDeadline: null,
      };
    },
    computed: {
      orgs() {
        if (this.item && this.item.assignmentExecutors)
          return this.item.assignmentExecutors.map((x) => {
            return { text: x.organizationName, id: x.organizationId };
          });
        else return [];
      },
      showDeadlineInput() {
        return this.assignmentExecutor?.statusId == 4 && !this.assignmentExecutor?.isExecute;
      },
    },
    created() {
      this.loadCard();
      this.checkPermissionAndRedirect(Constants.Permissions.MpkAssignmentAccess);
      this.checkPermission(Constants.Permissions.MpkAssignmentViewAllFoiv).then((response) => {
        this.viewAllExecutor = response.data;
      });
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;

            var roleId = 0;
            var profile = this.$store.state.auth?.oidc?.profile;
            if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));

            if (profile?.roleIds && profile?.roleIds.length > 0) roleId = Array.from(profile?.roleIds)[0];

            this.canStart = roleId == 3;
            this.canSendComment = this.item.canComment && (roleId == 7 || roleId == 8);

            this.item.assignmentExecutors.forEach(function (el) {
              el.canEdit = el.status.roleCanEdit != '' && el.status.roleCanEdit.indexOf(roleId) > -1;
              el.canApprove = el.status.roleCanApprove != '' && el.status.roleCanApprove.indexOf(roleId) > -1;
            });
            this.itemDeadline = this.item.deadline;
          });
        } else {
          this.close();
        }
      },
      setPostponed(assignmentExecutor) {
        API.setPostponed(assignmentExecutor.id, !assignmentExecutor.postponed)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      createReport(assignmentExecutor) {
        var reportEl = assignmentExecutor.mpkAssignmentExecutorReports?.find((x) => !x.isApprove);
        this.assignmentExecutorId = assignmentExecutor.id;
        this.modal = Object.assign(
          {},
          {
            report: reportEl?.report,
            files: [],
          },
        );
        if (reportEl?.files) {
          reportEl?.files.forEach((f) => {
            this.modal.files.push(Object.assign({}, f));
          });
        }
        this.dialogMakeReport = true;
      },
      createReportSend() {
        this.v$.$touch();
        if (this.v$.modal.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          id: this.assignmentExecutorId,
          assignmentExecutorId: this.assignmentExecutorId,
          report: this.modal.report,
          documentsIds: this.modal.files.map((x) => x.id),
        };

        API.updateReport(itemToSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.dialogMakeReport = false;
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      setCancelStatus(statusId, assignmentExecutor) {
        this.statusId = statusId;
        this.assignmentExecutorId = assignmentExecutor.id;
        this.dialogCancelStatus = true;
      },
      cancelStatusSend() {
        this.v$.$touch();
        if (this.v$.reason.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          assignmentExecutorId: this.assignmentExecutorId,
          reason: this.reason,
          statusId: this.statusId,
        };

        API.cancelStatus(itemToSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.dialogCancelStatus = false;
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      cancelReport(assignmentExecutor) {
        this.assignmentExecutorId = assignmentExecutor.id;
        this.dialogCancel = true;
      },
      cancelReportSend() {
        this.v$.$touch();
        if (this.v$.reason.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          assignmentExecutorId: this.assignmentExecutorId,
          reason: this.reason,
        };

        API.refuseReport(itemToSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.dialogCancel = false;
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      confirmReport(assignmentExecutor) {
        this.dialogConfirm = false;

        this.updateModal(assignmentExecutor);

        this.$nextTick(() => {
          this.itemDeadline = this.item.deadline;
          this.assignmentExecutor = assignmentExecutor;
          this.dialogConfirm = true;
        });
      },
      updateModal(assignmentExecutor) {
        var reportEl = assignmentExecutor.mpkAssignmentExecutorReports?.find((x) => !x.isApprove);
        this.assignmentExecutorId = assignmentExecutor.id;
        this.modal = Object.assign(
          {},
          {
            report: reportEl?.report,
            files: [],
          },
        );
        if (reportEl?.files) {
          reportEl?.files.forEach((f) => {
            this.modal.files.push(Object.assign({}, f));
          });
        }
      },
      confirmReportSend() {
        this.v$.$touch();
        if (this.v$.modal.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = Object.assign({ deadline: this.itemDeadline }, this.assignmentExecutor);
        itemToSave.report = this.reason;

        //отправляем отчет на следующий шаг
        API.approveReport(itemToSave)
          .then(() => {
            this.dialogConfirm = false;
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },

      startComment() {
        this.dialogStartComment = true;
      },
      startCommentSend() {
        this.v$.$touch();
        if (this.v$.comment.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          assignmentId: this.item.id,
          comment: this.comment,
        };

        //отправляем отчет на следующий шаг
        API.sendComment(itemToSave)
          .then(() => {
            this.dialogStartComment = false;
            this.comment = '';
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },

      onStart() {
        this.dialogOnStart = true;
        this.rekvizit = this.item.razdelProtocal;
      },
      onStartSend() {
        this.v$.$touch();
        if (this.v$.rekvizit.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          id: this.item.id,
          razdelProtocal: this.rekvizit,
        };

        //отправляем отчет на следующий шаг
        API.startAssignment(itemToSave)
          .then(() => {
            this.dialogOnStart = false;
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      getStatusClassIcon(el) {
        //cir-done //cir-close //cir-inWork
        if (el.postponed || el.statusId > Constants.mpkAssignemntStatusDoneId) return 'cir-close';
        if (el.statusId == Constants.mpkAssignemntStatusDoneId) return 'cir-done';
        return this.item.overDue ? 'cir-late' : 'cir-inWork';
      },
      getStatusClassColor(el) {
        //text-green //text-red //text-yellow
        if (el.postponed) return 'text-red';
        if (el.statusId == Constants.mpkAssignemntStatusDoneId) return 'text-green';
        return this.item.overDue ? 'text-red' : 'text-yellow';
      },
      close() {
        const queryParams = history.state?.location;
        if (queryParams) {
          this.$router.push({ name: 'Assignments', query: queryParams, state: { location: null } });
        } else {
          this.$router.push({ name: 'Assignments' });
        }
      },
      showRefuseReason(el) {
        this.dialogRefuse = true;
        this.refuseReasons = el.mpkAssignmentExecutorRefuses;
      },
      showComments(comments) {
        this.dialogComment = true;
        this.commentList = comments;
      },

      fileUploaded(e) {
        this.modal.files.push(e.data);
      },
      removeFile(e) {
        var file = this.modal.files.find((x) => x.id == e.id);
        var index = this.modal.files.indexOf(file);
        if (index !== -1) {
          this.modal.files.splice(index, 1);
        }
      },
      returnFromDone(data) {
        API.returnFromDone(data.id)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          })
          .finally(() => {
            this.loadCard();
          });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        modal: {
          report: { required },
        },
        reason: { required },
        itemDeadline: { required },
        rekvizit: { required },
        comment: { required },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .card-mpk {
    &__body {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__card {
      padding: 30px;
      max-width: 705px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      &-row {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px dashed #d7d7d7;
        p {
          font-weight: 300;
          font-size: 14px;
          line-height: 150%;
          color: #343c44;
          margin-bottom: 0px;
          &.title-column {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #343c44;
            margin-bottom: 10px;
          }
        }
      }
      &-col {
        &1 {
          padding-right: 16px;
          max-width: 431px;
          width: 100%;
        }
        &2 {
          max-width: 201px;
          width: 100%;
        }
      }
    }
    .report {
      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #787f8f;
      }
      &-text {
        min-height: 50px;
        margin-bottom: 16px;
      }
      &-column {
        .btn-container {
          button {
            margin-right: 8px;
            margin-bottom: 8px;
            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .status {
      &-container {
        .icon {
          margin-right: 6px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 1125px) {
    .card-mpk .chat-btn .icon {
      display: none;
    }
    .card-mpk__body {
      flex-wrap: wrap;
    }
    .card-mpk__card {
      order: 1;
      max-width: unset;
    }
    .card-mpk__card-row {
      justify-content: space-between;
    }
    .card-mpk__card-col1,
    .card-mpk__card-col2 {
      max-width: unset;
    }
  }
  @media (max-width: 767px) {
    .card-mpk__card {
      padding: 30px 12px;
    }
  }
  @media (max-width: 710px) {
    .card-mpk__card-row {
      flex-wrap: wrap;
      .card-mpk__card-col1 {
        margin-bottom: 8px;
      }
      .card-mpk__card-col2,
      .card-mpk__card-col1 {
        padding-right: 0px;
      }
      &.report {
        .card-mpk__card-col2 {
          order: 0;
          margin-bottom: 8px;
        }
        .card-mpk__card-col1 {
          order: 1;
        }
        .btn-container {
          flex-wrap: wrap;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .card-mpk__card-row {
      &.report {
        .btn-container {
          flex-wrap: wrap;
          button {
            width: 100%;
            margin-right: 0px;
            &:nth-of-type(1) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  .deadline {
    color: #787f8f;
    display: block;
  }
</style>
