<template>
  <!-- Чат -->
  <div class="card-mpk__chat-container">
    <!-- появляется этот селект только у админа -->
    <div class="chat-select">
      <p>Задать вопрос исполнителю</p>
      <selectComponent placeholder="Выберите исполнителя" :options="orgList" v-model="orgId" />
    </div>
    <div class="chat-btn" v-if="orgId">
      <IconComponent name="question_answer" />
      <ButtonStock
        class="w100"
        btnType="secondary"
        title="Задать уточняющий вопрос"
        @click="createChat"
        v-if="!chatExists"
      />
      <ButtonStock class="w100" btnType="secondary" title="Перейти к чату по поручению" @click="gotoChat" v-else />
    </div>
    <div class="card-mpk__chat" v-if="false">
      <!-- <p class="card-mpk__chat-ask dashedLine">
              Задать уточняющий вопрос
            </p> -->
      <div class="card-mpk__chat-header">
        <p>
          Чат с исполнителем:
          <span> Александр Сергеев </span>
        </p>
        <IconComponent name="triple-dots" />
      </div>
      <!-- сообщения здесь -->
      <div class="card-mpk__chat-body">
        <!-- you -->
        <div class="card-mpk__chat-replay you">
          <img src="~@/assets/images/boy.png" alt="" />
          <div class="textBlock">
            <div class="textBlock-head">
              <span class="who"> Александр Сергеев </span>
              <span class="time"> 12:45 </span>
            </div>
            <p class="text">
              Ольга Николаевна, последние корректировки были внесены не позднее, чем за сутки сдачи отчета.
            </p>
          </div>
        </div>
        <!-- собеседник -->
        <div class="card-mpk__chat-replay interlocutor">
          <img src="~@/assets/images/avatar1.png" alt="" />
          <div class="textBlock">
            <div class="textBlock-head">
              <span class="who"> Ирина Сергеевна </span>
              <span class="time"> 13:05 </span>
            </div>
            <p class="text">Ок</p>
          </div>
        </div>
        <!-- you -->
        <div class="card-mpk__chat-replay you">
          <img src="~@/assets/images/boy.png" alt="" />
          <div class="textBlock">
            <div class="textBlock-head">
              <span class="who"> Александр Сергеев </span>
              <span class="time"> 13:46 </span>
            </div>
            <p class="text">
              Ольга Николаевна, Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni ducimus quos, accusantium
              quas illum doloribus aut, doloremque ipsam ipsum est exercitationem nemo distinctio suscipit aliquam!
              Perspiciatis, adipisci fugit. Quia, eum.
            </p>
          </div>
        </div>
        <!-- собеседник -->
        <div class="card-mpk__chat-replay interlocutor">
          <img src="~@/assets/images/avatar1.png" alt="" />
          <div class="textBlock">
            <div class="textBlock-head">
              <span class="who"> Ирина Сергеевна </span>
              <span class="time"> 13:47 </span>
            </div>
            <p class="text">Ок</p>
          </div>
        </div>
      </div>
      <!-- ввод -->
      <div class="card-mpk__chat-input">
        <input type="text" placeholder="Введите сообщение" />
        <IconComponent name="fileUpload" />
      </div>
    </div>
  </div>
</template>

<script>
  import selectComponent from '@/common/components/selectComponent.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/assignment';

  export default {
    name: 'AssignmentChat',
    components: {
      ButtonStock,
      IconComponent,
      selectComponent,
    },
    props: {
      orgList: {
        type: Array,
        default: () => [],
      },
      assignmentId: {
        type: Number,
        required: true,
      },
    },
    computed: {
      chatExists() {
        return this.chats[this.orgId] > 0;
      },
    },
    data() {
      return {
        orgId: null,
        chats: [],
      };
    },
    created() {
      this.loadChats();
    },
    methods: {
      loadChats() {
        API.getChats(this.assignmentId).then((res) => {
          if (res && res.data) {
            this.chats = res.data;
          }
        });
      },
      createChat() {
        API.createChat({ assignmentId: this.assignmentId, orgId: this.orgId })
          .then((res) => {
            if (res && res.data && res.data.id) {
              this.$router.push({ name: 'Messenger', params: { initialDialogId: res.data.id } });
            }
          })
          .catch(() => {
            Constants.alert.fire('Создание чата', Constants.commonError, 'error');
          });
      },
      gotoChat() {
        let chatId = this.chats[this.orgId];
        if (chatId > 0) {
          this.$router.push({ name: 'Messenger', params: { initialDialogId: chatId } });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-mpk {
    &__chat {
      background: #ffffff;
      border: 1px solid #01a39d;
      border-radius: 5px;
      max-width: 375px;
      min-height: 375px;
      max-height: 607px;
      width: 100%;
      position: relative;
      padding-bottom: 6px;
      &-container {
        button {
          margin-left: auto;
          margin-bottom: 8px;
        }
      }
      &-ask {
        cursor: pointer;
        position: absolute;
        font-weight: 700;
        // top: -30px;
        top: -55px;
        right: 0px;
        margin-bottom: 0px;
      }
      &-header {
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;
        background: #01a39d;
        p,
        span {
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
        }
        p {
          margin-bottom: 0px;
          font-weight: 700;
        }
        span {
          font-weight: 400;
        }
      }
      &-body {
        padding: 0px 20px;
        max-height: 495px;
        overflow-y: auto;
        border-bottom: 1px solid #01a39d;
        display: flex;
        flex-direction: column;
      }
      &-replay {
        display: flex;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 32px;
        &:last-of-type {
          margin-bottom: 28px;
        }
        img {
          border-radius: 50%;
          width: 38px;
          height: 38px;
          object-fit: cover;
        }
        .textBlock {
          &-head {
            margin-bottom: 8px;
          }
          .who,
          .time {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
          .time {
            display: inline;
            opacity: 0.5;
          }
          .text {
            padding: 12px 16px;
            background: #e8e8e8;
            border-radius: 4px;
            margin-bottom: 0px;
          }
        }
        &.you {
          img {
            order: 0;
            margin-right: 8px;
          }
        }
        &.interlocutor {
          justify-content: flex-end;
          img {
            order: 1;
            margin-left: 8px;
          }
        }
      }
      &-input {
        width: 100%;
        // height: 100%;
        max-height: 70px;
        position: relative;
        padding: 20px 0px;
        input {
          outline: none;
          border: none;
          background: #ffffff;
          width: 100%;
          height: 100%;
          padding: 0px 38px 0px 20px;
        }
        .icon {
          position: absolute;
          top: 18px;
          right: 10px;
        }
      }
    }
    .chat {
      &-btn {
        position: relative;
        .icon {
          position: absolute;
          top: 12px;
          left: 60px;
        }
      }
      &-select {
        margin-bottom: 18px;
        p {
          font-size: 14px;
          line-height: 160%;
          color: #231f20;
        }
      }
    }
  }
  @media (max-width: 1125px) {
    .card-mpk .chat-btn .icon {
      display: none;
    }
    .card-mpk__chat-container {
      order: 0;
      width: 35%;
      margin-bottom: 8px;
    }
    .card-mpk__chat {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .card-mpk__chat-container {
      width: 80%;
    }
  }
  @media (max-width: 425px) {
    .card-mpk__chat-container {
      width: 100%;
    }
  }
</style>
